





















import {Vue, Prop, Component} from 'vue-property-decorator'

@Component({})
export default class extends Vue {
    @Prop({required: true}) private title!: string
    @Prop({default: false}) private show!: boolean
    @Prop({default: false}) private config!: any

    text= 'text'

    accept= '.xls, .xlsx'

    actions(){
        return "J27122";
    }

    success(item: any) {
        console.log(item);

    }

    close(data?: any) {
        this.$emit('close', data)
    }
}
